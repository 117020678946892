import type { Coin } from '@base/types'

export const useCoins = defineStore('coins', () => {
  const tokens = useState<Coin[]>('tokens', () => [])
  const fiatCoins = useState<Coin[]>('fiatCoins', () => [])
  const fetchInterval = ref(60)
  const nextFetchIn = ref(fetchInterval.value)

  const visibility = useDocumentVisibility()
  const { currencies } = storeToRefs(useCodes())

  /**
   * Resolves the currencies by filtering the fiat and crypto currencies from the `currencies` array.
   * The czk currency is resolved first, followed by other currencies.
   * The currencies are extended with the `price`, `chart` and `change24HPercent` properties.
   *
   * @return {Promise<void>} A promise that resolves when the currencies have been resolved.
   */
  const _resolveCurrencies = async (): Promise<void> => {
    // first resolve CZK fiat
    const czkCoin = await resolveCurrencyToCoin(currencies.value.find(c => c.code === 'CZK')!)

    const [fiat, crypto] = await Promise.all([
      Promise.all(currencies.value.filter(c => c.type === 'FIAT').map(c => resolveCurrencyToCoin(c, czkCoin))),
      Promise.all(currencies.value.filter(c => c.type === 'CRYPTO').map(c => resolveCurrencyToCoin(c, czkCoin))),
    ])

    tokens.value = crypto
    fiatCoins.value = fiat
  }

  /**
 * Fetches coins and resolving the currencies prices and details.
 *
 * @param {boolean} [force] - Whether to force the fetch even if the tokens array is not empty.
 * @return {Promise<void>} - A promise that resolves when the currencies have been resolved.
 */
  const fetchCoins = async (force?: boolean) => {
    if (!force && tokens.value.length)
      return

    await _resolveCurrencies()
  }

  useAsyncData(() => fetchCoins())

  onMounted(() => {
    const interval = useIntervalFn(() => {
      nextFetchIn.value--
      if (nextFetchIn.value === 0) {
        fetchCoins(true)
        nextFetchIn.value = fetchInterval.value
      }
    }, 1000)

    watch(visibility, () => {
      if (visibility.value === 'hidden')
        interval.pause()
      else
        interval.resume()
    })
  })

  const getTokenByCode = (code: string) => tokens.value.find(c => c.code === code)
  const getFiatCoinByCode = (code: string) => fiatCoins.value.find(c => c.code === code)
  const getCoinByCode = (code: string) => tokens.value.find(c => c.code === code) || fiatCoins.value.find(c => c.code === code)
  const getReactiveCoinByCode = (code: string) => computed(() => tokens.value.find(c => c.code === code) || fiatCoins.value.find(c => c.code === code))

  return {
    coins: computed(() => [...fiatCoins.value, ...tokens.value]),
    tokens,
    fiatCoins,
    fetchInterval,
    nextFetchIn,
    getTokenByCode,
    getFiatCoinByCode,
    getCoinByCode,
    getReactiveCoinByCode,
    fetchCoins,
  }
})
